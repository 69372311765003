$(function () {

  // 懒加载
  $('img.lazyload').lazyload({
    effect: 'fadeIn'
  })

  // 滚动
  // 侧栏滚动
  var $sidebar = $('#sidebar'),
    $sidebars = $('#sidebars'),
    sidebarWidth = $sidebars.width(),
    sidebarHeight = $sidebar.height(),
    sidebarOffsetTop = $sidebar.length ? $sidebar.offset().top : 0;

  // if ($sidebar.length > 0) {
  //   sidebarWidth = $sidebars.width()
  //   var sidebarHeight = $sidebar.height(),
  //     sidebarOffsetTop = $sidebar.offset().top
  // }

  //浏览器窗口大小变化时
  $(window).resize(function () {
    sidebarWidth = $sidebars.width()
    if ($(window).width() < 768) {
      $sidebar.data('scroll', false)
    } else {
      $sidebar.data('scroll', true)
    }
  })

  // 滚动控制
  $(window).scroll(function () {
    var scrollTop = $(this).scrollTop();
    var footerTop = $('#footer').offset().top;

    // 主导航
    if (scrollTop >= 20) {
      $('#header .header-wrap').addClass('is-scroll')
    } else {
      $('#header .header-wrap').removeClass('is-scroll')
    }

    // 顶部
    if (scrollTop >= 100) {
      $('.rw-roll--top').addClass('d-block')
    } else {
      $('.rw-roll--top').removeClass('d-block')
    }

    // 侧栏
    if ($sidebar.length > 0 && $(window).width() >= 768) {
      if (scrollTop >= sidebarOffsetTop) {
        if (scrollTop <= (footerTop - sidebarHeight - 100)) {
          $($sidebar).css({ 'position': 'fixed', 'top': 100, 'bottom': 'auto', 'width': sidebarWidth });
        } else {
          $($sidebar).css({ 'position': 'absolute', 'top': 'auto', 'bottom': 0, 'width': sidebarWidth });
        }
      } else {
        $($sidebar).css({ 'position': 'relative', 'top': 'auto', bottom: 'auto', 'width': sidebarWidth });
      }
    }
  });

  // 头部
  $('#go-top').click(function () {
    $(window).scrollTop(0)
  });

  // 搜索
  $('#btn-rw-search').click(function () {
    $('.rw-search').removeClass('d-none').addClass('d-block')
  });
  $('.rw-search a.close').click(function () {
    $('.rw-search').removeClass('d-block').addClass('d-none')
  });

  // 手机菜单
  $('#menu-mobile').click(function () {
    if (!$(this).hasClass('menu-mobile--close')) {
      $(this).addClass('menu-mobile--close')
      $('#navbar').addClass('is-show')
      $(document.body).addClass('rw-body-hidden')
    } else {
      $(this).removeClass('menu-mobile--close')
      $('#navbar').removeClass('is-show')
      $(document.body).removeClass('rw-body-hidden')
    }
  });


  $('#carouselCategoryRow .product-item').click(function () {
    $('#carouselCategoryRow .product-item').removeClass('is-active')
    $(this).addClass('is-active')
  })

  // ajax 全局
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
  });

})

/**
 * 发送消息视图
 *
 * @param {number} site_id
 * @param {number} content_id
 * @return {void}
 */
var sendMessageView = function (site_id, content_id) {
  // console.log($('#modal-send-message').length)
  if ($('#modal-send-message').length) {
    $('#modal-send-message').modal('show')
  } else {
    $.get(`/send-message-modal`, {site_id, content_id}, function (res) {
      if (res.status === 200) {
        $('body').append(res.html)
        $('#modal-send-message').modal('show')
      } else {
        alert('An error occurred');
      }
    })
  }
}

/**
/**
 * 发布问题视图
 *
 * @param {number} site_id
 * @param {number} content_id
 * @return {void}
 */
var publishQuestionView = function (site_id, content_id) {
  // console.log($('#modal-send-message').length)
  var publishQuestionEl = $('#modal-publish-question');
  if (publishQuestionEl.length) {
    publishQuestionEl.modal('show')
  } else {
    $.get(`/question/dialog-modal`, {site_id, content_id}, function (res) {
      if (res.status === 200) {
        $('body').append(res.html)
        $('#modal-publish-question').modal('show')
      } else {
        alert('An error occurred');
      }
    })
  }
}